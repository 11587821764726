<template>
  <div>
    <div
      class="card"
      style="background: #ffffff;
              border-radius: 10px;
              "
    >
      <div
        class="pl-3 pr-3 pt-2"
      >
        <div class="">
          <span class="saj-title">
            {{ $t("Search Filter") }}
          </span>
        </div>
        <div class="row align-items-end mb-1">
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Subsidiary") }}:</label>
            <v-select
              v-model="selectedSub"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Subsidiary')"
              :options="subList"
              :reduce="sub => sub.value"
              label="text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Grade Set") }}:</label>
            <v-select
              v-model="selectedGrade"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Grade Set')"
              :options="gradesetList"
              :reduce="grade => grade.value"
              :disabled="selectedSub === null"
              label="text"
              @input="selectedType = null"
            />
          </b-col>
          <b-col
            v-if="selectedGrade === 6 || selectedGrade === 5"
            cols="12"
            md="4"
            class="mb-md-0 pt-1"
          >
            <label class="saj-text">{{ $t("Evaluation Type") }}:</label>
            <b-form-select
              id="icons-search"
              v-model="selectedType"
              :options="evaluationList"
              size="sm"
              class="saj-text"
            />
          </b-col>
        </div>
        <div
          class="d-flex justify-content-end mt-1 mb-1"
        >
          <b-button
            v-if="selectedGrade === 6 || selectedGrade === 5"
            md="4"
            variant="primary"
            class="d-flex justify-content-center"
            style=""
            :disabled="selectedType === null"
            @click="searchWeightage()"
          >
            <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
          </b-button>
          <b-button
            v-else
            md="4"
            variant="primary"
            class="d-flex justify-content-center"
            style=""
            :disabled="selectedGrade === null"
            @click="searchWeightage()"
          >
            <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
          </b-button>
          <b-button
            md="4"
            variant="primary"
            class="ml-1"
            style=""
            @click="clearSearch()"
          >
            <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
          </b-button>
        </div>
      </div>

    </div>
    <weightage
      v-if="search"
      :key="refreshGraph"
      class=""
      :sub-id="selectedSub"
      :grade-set="selectedGrade"
      :eval-type="selectedType"
    />
  </div>
</template>
<script>
import {
  BCol,
  BFormSelect,
  BButton,
} from "bootstrap-vue"
import vSelect from 'vue-select'
import weightage from '@/views/pages/weightage/weightage_modal.vue'
// import {
//   max_value,
//   min_value,
// } from 'vee-validate/dist/rules'
// import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'

// extend('max_value', max_value)
// extend('min_value', min_value)

export default {
  components: {
    vSelect,
    BCol,
    BFormSelect,
    BButton,
    weightage,
  },
  props: {

  },
  data(){
    return {
      selectedSub: null,
      subList: [],
      gradesetList: [],
      selectedGrade: null,
      selectedType: null,
      evaluationList: [
        { text: `${this.$i18n.t('Choose Evaluation Type')}`, value: null, disabled: true },
        { text: `${this.$i18n.t('KRA')}`, value: 1 },
        { text: `${this.$i18n.t('JD')}`, value: 0 },
      ],
      search: false,
      refreshGraph: 0,
    }
  },
  watch: {

  },
  mounted(){
    this.getSubsidiary()
    this.getGradeSet()
  },
  methods: {
    getSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allbusinessUnit = response.data.data.subsidiaries

        this.subList = this.allbusinessUnit.map(b => ({
          text: b.subsidiary_name,
          value: b.id,
        }))
      })
    },
    getGradeSet(){
      this.$axios.get(`${this.$baseUrl}/gradeSet/get_all`).then(response => {
        const res = response.data.data

        this.gradesetList = res.map(b => ({
          text: b.name,
          value: b.id,
        }))
      })
    },
    searchWeightage(){
      if (this.search){
        this.search = false
      }
      this.search = true
      this.refreshGraph += 1
    },
    clearSearch(){
      this.selectedSub = null
      this.selectedGrade = null
      this.selectedType = null
      this.search = false
    },
  },
}
</script>
