<template>
  <div
    class="card"
    style="background: #ffffff;
              border-radius: 10px;
              "
  >
    <div
      class="card"
      style="background: #ffffff;
              border-radius: 10px;
              overflow-y:auto;"
    >
      <div
        class="pl-3 pr-3 pt-2"
      >
        <div
          v-if="!isEdit"
          class="d-flex justify-content-end mb-1"
        >
          <b-button
            md="4"
            variant="primary"
            class="d-flex justify-content-center"
            style=""
            @click="isEdit = !isEdit"
          >
            <span class="saj-button d-md-none d-lg-block"> {{ $t("Edit") }} </span>
          </b-button>
        </div>
        <div
          v-else
          class="d-flex justify-content-end mb-1"
        >
          <b-button
            md="4"
            variant="primary"
            class="d-flex justify-content-center"
            style=""
            @click="saveWeightage()"
            :disabled="totalWeightage != 100"
          >
            <span class="saj-button d-md-none d-lg-block"> {{ $t("Save") }} </span>
          </b-button>
          <b-button
            md="4"
            class="ml-1"
            style="
                    background-color: rgb(11, 16, 60) !important;
                    border-color: rgb(11, 16, 60) !important;
                  "
            @click="isEdit = !isEdit, weightageList = weightageList"
          >
            <span class="saj-button d-md-none d-lg-block"> {{ $t("Cancel") }}</span>
          </b-button>
        </div>
        <div>
          <!-- <validation-observer ref="validateWeightage"> -->
          <b-table
            sticky-header="500px"
            :items="weightageList"
            :fields="weightageField"
            responsive
            bordered
            show-empty
          >
            <template #head()="data">
              <span
                class="saj-text d-flex justify-content-center"
              >{{ $t(data.label) }}</span>
            </template>
            <template #cell(index)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ data.index + 1 }}
              </div>
            </template>
            <template #cell(result)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                {{ $t(data.item.result) }}
              </div>
            </template>
            <template #empty="">
              <h4 style="text-align: center; font-style: italic">
                {{ $t('There are no records to show') }}
              </h4>
            </template>
            <template #cell(weightage)="data">
              <div
                class="saj-text d-flex justify-content-center"
              >
                <b-form-input
                  v-model="weightageList[data.index].weightage"
                  type="number"
                  class="d-flex justify-content-center align-items-center saj-title pointer"
                  style="color: black; font-size: 14px; width: auto; padding-right: 10px; padding-left: 10px; height: 35px; width: 55px; text-align: center"
                  :disabled="!isEdit"
                   min="1"
                  @input="calculateTotal()"
                />
              </div>
            </template>
            <template #custom-foot="">
              <b-tr>
                <b-td
                  colspan="2"
                  variant=""
                  class="text-right"
                >
                  <b>{{ $t("Summary Score") }}</b>
                </b-td>
                <b-td
                  colspan="1"
                  variant=""
                  class="text-center d-flex flex-column justify-content-center align-items-center"
                >
                  <b-form-input
                    v-model="totalWeightage"
                    type="number"
                    class="d-flex justify-content-center align-items-center saj-title pointer"
                    style="color: black; font-size: 14px; width: auto; padding-right: 10px; padding-left: 10px; height: 35px; width: 55px; text-align: center"
                    disabled
                  />
                  <small
                    v-if="totalWeightage !== 100"
                    class="text-danger"
                    style="color: #EA5455 !important;"
                  >{{ $t('Total must be 100%') }}
                  </small>
                </b-td>
              </b-tr>
            </template>
          </b-table>
          <!-- </validation-observer> -->
          <div class="d-flex justify-content-start align-items-center mt-4 mb-4">
            <p class="saj-subtitle m-0">
              {{ $t("Internal / Industrial Involvement Weightage") }} :
            </p>
            <b-form-input
              v-model="internalWeightage"
              type="number"
              class="saj-title pointer ml-1"
              style="color: black; font-size: 14px; width: auto; padding-right: 10px; padding-left: 10px; height: 35px; width: 55px; text-align: center"
              :disabled="!isEdit"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BTable,
  BTr,
  BTd,
  BButton,
  BFormInput,
} from "bootstrap-vue"
import { required } from '@validations'
import SAJToast from "@/component/saj-toastification.vue"

export default {
  components: {

    BTable,
    BTr,
    BTd,
    BButton,
    // ValidationProvider,
    // ValidationObserver,
    BFormInput,

  },
  props: {

    subId: {
      type: Number,
      default: null,
    },
    gradeSet: {
      type: Number,
      default: null,
    },
    evalType: {
      type: Number,
      default: null,
    },

  },
  data(){
    return {

      weightageField: [
        {
          key: 'index', label: 'No',
        },
        {
          key: 'result', label: 'Result', class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        {
          key: 'weightage', label: 'Weightage', class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
      ],
      isEdit: false,
      required,
      totalWeightage: null,
      internalWeightage: null,
      weightageList: [],
      isNew: false,

    }
  },
  mounted(){
    this.searchWeightage()
  },
  methods: {
    calculateTotal(){
      let total = 0
      this.weightageList.forEach(x => {
        total += Number(x.weightage)
        // console.log('tot', total)
      })

      this.totalWeightage = total
    },
    searchWeightage(){
      const params = new URLSearchParams()

      params.append(`subsidiary_id`, this.subId)
      params.append('role_id', this.gradeSet)

      this.$axios.get(`${this.$baseUrl}/weightage/get_role_percentage`, { params }).then(response => {
        const res = response.data.data

        let type = null

        this.isNew = res === null

        if (this.evalType === null){
          type = 1
        } else {
          type = this.evalType
        }

        if (res !== null){
          const a = [
            {
              result: type === 0 ? "Overall Assessment" : "Key Result Area",
              key: type === 0 ? 'overall_assesment' : 'key_result_area',
              weightage: type === 0 ? res.overall_assesment : res.key_result_area,
            },
            {
              result: 'Core Competencies',
              key: 'core_competency',
              weightage: res.core_competency,

            },
            {
              result: 'Behavioural Competencies',
              key: 'behavioural_competency',
              weightage: res.behavioural_competency,

            },
          ]

          let b = null

          if (this.gradeSet === 1 || this.gradeSet === 2){
            b = [
              {

                result: 'Training',
                key: 'training',
                weightage: res.training,

              },
              {

                result: 'Self Learning',
                key: 'self_learning',
                weightage: res.self_learning,

              },
            ]
          } else {
            b = [{

              result: 'Training and Development',
              key: 'training',
              weightage: res.training,

            },
            ]
          }

          this.weightageList = a.concat(b)

          this.internalWeightage = res.internal_industry_involement
        } else {
          const a = [
            {
              result: type === 0 ? "Overall Assessment" : "Key Result Area",
              key: type === 0 ? 'overall_assesment' : 'key_result_area',
              weightage: 0,
            },
            {
              result: 'Core Competencies',
              key: 'core_competency',
              weightage: 0,

            },
            {
              result: 'Behavioural Competencies',
              key: 'behavioural_competency',
              weightage: 0,

            },
          ]

          let b = null

          if (this.gradeSet === 1 || this.gradeSet === 2){
            b = [
              {

                result: 'Training',
                key: 'training',
                weightage: 0,

              },
              {

                result: 'Self Learning',
                key: 'self_learning',
                weightage: 0,

              },
            ]
          } else {
            b = [{

              result: 'Training and Development',
              key: 'training',
              weightage: 0,

            },
            ]
          }

          this.weightageList = a.concat(b)

          this.internalWeightage = 0
        }
        // this.gradesetList = res.map(b => ({
        //   text: b.name,
        //   value: b.id,
        // }))
        this.calculateTotal()
      })
    },

    saveWeightage(){
      const data = new FormData()

      this.weightageList.forEach(x => {
        data.append(x.key, x.weightage)
      })

      // eslint-disable-next-line no-restricted-syntax
      // for (const pair of data.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }

      data.append('subsidiary_id', this.subId)
      data.append('role_id', this.gradeSet)
      data.append('internal_industry_involement', this.internalWeightage)

      let type = null

      if (this.evalType === null){
        type = 1
      } else {
        type = this.evalType
      }

      if (type === 1) {
        data.append('overall_assesment', 0)
      } else {
        data.append('key_result_area', 0)
      }

      if (this.gradeSet === 3 || this.gradeSet === 4 || this.gradeSet === 5 || this.gradeSet === 6){
        data.append('self_learning', 0)
      }

      let url = null

      if (this.isNew){
        url = '/weightage/add_percentage'
      } else {
        url = '/weightage/update_percentage'
      }

      this.$axios.post(`${this.$baseUrl}${url}`, data)
        .then(() => {
          // this.$emit('reload-subcore')
          this.reload()

          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully Updated')}.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )

          this.isEdit = false
          if (this.isNew){
            this.isNew = false
          }
        })
    },

    reload(){
      this.$emit('reload')
    },
  },
}
</script>
